<template>
  <div class="confirmation-header-container">
    <div class="confirmation-content d-flex justify-content-between align-items-center">
      <div class="confirmation-text">
        <h4 class="confirmation-number">
          Thank you for hiring {{ invoice.contact.name }} to complete the registration and title process for your vehicle. Your payment has been processed.
        </h4>
        <h4 class="confirmation-number">
          Your order confirmation number is <span>{{ '#' + invoiceNumber }}</span>
        </h4>
        <p>
          Please complete the following steps to finalize your registration. You can track your order on the 'Filing Orders' page from the home page.
        </p>
      </div>
      <picture>
        <source
          :srcset="require('@images/illustrations/products/car-teal-500px.webp')"
          type="image/webp"
        >
        <img
          class="confirmation-image"
          :src="require('@images/illustrations/products/car-teal-500px.png')"
          alt="Car illustration"
        >
      </picture>
    </div>
    <div class="steps">
      <h3>1. Complete Purchasing Requirements and Paperwork</h3>
      <ul>
        <li>ONLY list the name of your LLC as the buyer.</li>
        <li>DO NOT list your human name.</li>
        <li>DO NOT cross out or white-out any information on the documents.</li>
        <li>Confirm that all listed owners of the vehicle have signed the document as sellers.</li>
        <li>Some states require that the seller’s signature is notarized. Please give us a call if you have questions.</li>
        <li>If the dealership requests your legal, natural signature as the buyer, sign: “[Your Signature], Member”.</li>
        <li>If the dealership wants to send documentation directly to Flathead County, ask that they give you the documents instead. Flathead County is currently NOT accepting mailed documents. Any documentation received by the county will be sent back to the dealer.</li>
        <li>Sign the title as seller using your human name. You are “selling” the vehicle to your LLC.</li>
        <li>ALWAYS include all documentation from the purchase (including all dealership documentation). If there are documents that we don’t need, we will send them back to you.</li>
        <li>Once we receive all of the required documents, we will submit them to the state.</li>
      </ul>
      <h3>2. Plates, Registration, and a New Title</h3>
      <ul>
        <li>The turnaround time to receive your plates and registration is 8-12 weeks once we have all required documents.</li>
        <li>Once we receive your plates and registration, we will overnight them to you.</li>
        <li>Your title will come roughly 40 days after you receive your plates and registration and will be sent with tracking to the mailing address on your account.</li>
        <li>We can order a temporary registration that is good for 40 days. The process to obtain a temp takes 7-10 business days, and we must have all required original documents before we make the request.</li>
      </ul>
      <h3>Exceptions</h3>
      <ul>
        <li>If you currently have a lien or may have a lien in the future, this process is more complicated and in some cases cannot be completed if you have a lien. Contact us for more information: (406) 552-0004.</li>
        <li>Import vehicles have a different set of requirements. Please contact us if you have an imported vehicle.</li>
      </ul>
      <p>We greatly appreciate your patience and understanding. Thank you!</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VehicleOrderConfirmation',
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    invoiceNumber() {
      return this.invoice.invoice_number
    },
  },
}
</script>

<style lang="scss"  scoped>
.confirmation-header-container {
  padding: 1.25em;
  background-color: #f9f9f9;
  font-size: 90%;
}

.confirmation-title {
  text-align: center;
  margin-bottom: 1.25em;
  color: #003366;
}

.confirmation-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.25em;
}

.confirmation-image {
  max-width: 150px;
  margin-left: 1.25em;
}

.confirmation-text {
  flex: 1;
}

.confirmation-number {
  margin: .6em 0;
}

p {
  margin: .6em 0;
  color: #555;
}

.steps {
  max-width: 800px;
  margin: 1.25em auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.25em;
}

h3 {
  margin-bottom: .6em;
  color: #003366;
  font-size: 1.5rem;
}

ul {
  list-style-type: "- ";
  margin-left: .3em;
  padding-left: 1.25em;
}

li {
  margin-bottom: .6em;
}
@media (max-width: 992px) {
  .confirmation-image {
    display: none;
  }

  .confirmation-content {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>

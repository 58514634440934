<template>
  <b-modal
    v-if="vehicle"
    id="add-vehicle-service-modal"
    title="Add Service"
    centered
    size="lg"
    hide-footer
    @show="getFilingProducts"
    @hide="hide"
  >
    <template slot="modal-title">
      <h3 class="no-padd no-margin">
        Add Services | {{ vehicle.make }} {{ vehicle.model }}
      </h3>
    </template>
    <ct-centered-spinner v-if="!loaded" />
    <div v-if="loaded">
      <div v-if="!serviceToAdd" class="padding-bottom-1-5rem">
        <div v-if="vehicleProducts.length === 0">
          <p>
            Please complete vehicle registration before adding new products.
          </p>
          <p>
            Ensure the registration contains a <em>VIN</em>.
          </p>
        </div>
        <div v-else>
          <h3>
            Select a product
          </h3>
        </div>
        <b-list-group class="w-100 pointer product-categories-list">
          <div>
            <selection-cards
              :items="vehicleProducts"
              @chosen="selectedService"
            />
          </div>
        </b-list-group>
      </div>
      <div v-if="serviceToAdd && !confirmOrder">
        <div class="button-container">
          <b-link
            class="py-0"
            variant="link"
            aria-label="Add Another Product link"
            @click="serviceToAdd = null"
          >
            <fa-icon icon="chevron-left" />
            Back / Add Another Product
          </b-link>
        </div>
        <div v-if="isOrgPlate()">
          <h5 class="padding-top-1-5rem">
            Choose your organizational plate design
          </h5>
          <p>
            <em>
              Note: Processing times can take several months depending on current stock of the requested plate.
            </em>
          </p>
          <multi-select
            v-model="selectedPlate"
            :options="vehicleOrgPlates"
            label="plate_name"
            :allow-empty="false"
            deselect-label=""
            select-label=""
            placeholder="Select one"
            class="padding-bottom-1-5rem"
            @select="updateVehicleOrgPlate"
          />
          <filing-checkout
            v-if="selectedPlate"
            :company="currentCompany"
            :product="serviceToAdd"
            @product-added="productAdded"
          />
        </div>
        <filing-checkout
          v-else
          :company="currentCompany"
          :product="serviceToAdd"
          @product-added="productAdded"
        />
      </div>
      <div v-if="confirmOrder">
        <div class="button-container">
          <b-link
            class="py-0"
            variant="link"
            aria-label="Order Another Product link"
            @click="resetForm"
          >
            <fa-icon icon="chevron-left" />
            Back / Order Another Product
          </b-link>
        </div>
        <vehicle-additional-service-confirmation :invoice="invoice" />
      </div>
      <div v-else>
        <payment-method-options
          v-if="readyToCheckout()"
        />
        <cart
          :processing-checkout="processingCheckout"
          button-text="Checkout"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Cart from '@/components/HireUs/Cart.vue'
import FilingCheckout from '@/components/HireUs/FilingCheckout.vue'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions.vue'
import SelectionCards from '@/components/shared/SelectionCards.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import MultiSelect from 'vue-multiselect'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import VehicleAdditionalServiceConfirmation
  from '@/components/Vehicles/VehicleAdditionalServiceConfirmation.vue'

export default {
  name: "AddVehicleServicesModal",
  components: {
    VehicleAdditionalServiceConfirmation,
    MultiSelect,
    CtCenteredSpinner,
    Cart,
    FilingCheckout,
    PaymentMethodOptions,
    SelectionCards,
  },
  mixins: [makeToastMixin],
  props: {
    vehicle: Object,
  },
  data() {
    return {
      serviceToAdd: null,
      processingCheckout: false,
      loaded: false,
      selectedPlate: null,
      confirmOrder: false,
      invoice: null,
      adminInvoiceOnly: false,
    }
  },
  computed: {
    ...mapGetters('vehicleRegistration', [
      'vehicleProducts',
      'vehicleOrgPlates',
      'vehicleFormData',
    ]),
    ...mapGetters('checkout', [
      'cartEmpty',
      'cartItems',
    ]),
    ...mapGetters('companies', ['currentCompany']),
  },
  destroyed() {
    this.$root.$off('cart-next-page')
    this.$root.$off('checkout-without-payment')
  },
  methods: {
    ...mapActions('companies', ['setCurrentCompany']),
    ...mapActions('checkout', [
      'checkout',
      'clearCart',
      'updateCartItemData',
    ]),
    ...mapActions('vehicleRegistration', [
        'setCurrentVehicle',
        'loadProducts',
        'loadOrgPlates',
        'getVehicleFormData',
    ]),
    hide() {
      this.clearCart()
      this.$root.$off('cart-next-page')
      this.$root.$off('checkout-without-payment')
    },
    async getFilingProducts () {
      this.$root.$on('cart-next-page', () => {
        this.proceedToPayment()
      })
      this.$root.$on('checkout-without-payment', () => {
        this.adminInvoiceOnly = true
        this.proceedToPayment()
      })
      this.loaded = false
      this.setVehicle()
      await this.loadProducts()
      await this.setCompany()
      await this.loadOrgPlates()
      this.loaded = true
    },
    async setCompany () {
      await this.setCurrentCompany({ id: this.vehicle.company_id })
    },
    setVehicle () {
      this.setCurrentVehicle({ vehicle: this.vehicle })
    },
    selectedService (service = null) {
      if (this.cartItems.some(cartItem => cartItem.product_id === service.id)) {
        this.errorToast('Error', 'Already in cart')
      }
      else {
        this.serviceToAdd = service
      }
    },
    async proceedToPayment () {
      this.processingCheckout = true
      let response = await this.checkout(this)
      this.processingCheckout = false
      if (response) {
        this.invoice = response.invoice
        this.confirmOrder = true
      }
    },
    async updateVehicleOrgPlate () {
      this.vehicle['org_plate'] = this.selectedPlate['plate_name']
      this.setVehicle()
      await this.setFormFields(this.serviceToAdd)
    },
    isOrgPlate() {
      return this.serviceToAdd &&
            (this.serviceToAdd.name === 'Organizational Plates' ||
            (this.serviceToAdd.name === 'Replacement Plates' && this.hasOrgPlateInCart()))
    },
    hasOrgPlateInCart() {
      return this.cartItems.some(item => item.title.toLowerCase().includes('organizational'))
    },
    needsOrgPlateAttention() {
      return !!(this.isOrgPlate() && !this.vehicle.hasOwnProperty('org_plate'))

    },
    readyToCheckout() {
      return !this.cartEmpty && !this.needsOrgPlateAttention()
    },
    async productAdded (product) {
      await this.setFormFields(product)
    },
    async setFormFields(product) {
      const cartItem = this.cartItems.find(cartItem => cartItem.product_id === product.id)
      if (cartItem) {
        await this.getVehicleFormData(cartItem.filing_method_id)
        let products = this.vehicleFormData
        if (this.hasOrgPlateInCart()) {
          products['vehicle_organizational_plate'] = this.vehicle['org_plate']
        }
        await this.updateCartItemData( { id: cartItem.id, data: products })
      }
    },
    resetForm() {
      this.serviceToAdd = null
      this.processingCheckout = false
      this.selectedPlate = null
      this.confirmOrder = false
      this.invoice = null
      this.adminInvoiceOnly = false
    },
  },
}
</script>

<style scoped>
  .padding-bottom-1-5rem {
    padding-bottom: 1.5rem;
  }
  .padding-top-1-5rem {
    padding-top: 1.5rem;
  }
  .no-margin {
    margin: 0;
  }
  .no-padding {
    padding: 0;
  }
</style>

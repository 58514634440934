<template>
  <b-modal
    v-if="active"
    id="edit-vehicle-registration-modal"
    title="Edit Vehicle"
    centered
    size="md"
    hide-footer
  >
    <b-spinner v-if="loading" />
    <template slot="modal-title">
      <h3 style="margin: 0; padding: 0">
        Edit Your Vehicle
      </h3>
    </template>
    <div v-if="!loading" class="mb-4">
      <div class="d-block">
        <form v-if="active" ref="form" @submit.prevent="updateVehicle">
          <validation-observer v-slot="{ invalid }">
            <b-row v-for="field in selectedFields()" :key="field.id">
              <b-col>
                <b-form-group :label="field.label">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.required ? `required|${field.key.toString()}` : null"
                    immediate
                    :name="field.label"
                  >
                    <b-form-input
                      v-if="field.type === 'text'"
                      v-model="mutableVehicle[field.key]"
                      type="text"
                      :state="!errors[0]"
                    />

                    <b-form-checkbox
                      v-if="field.type === 'boolean'"
                      v-model="mutableVehicle[field.key]"
                      button-variant="success"
                      switch
                      size="md"
                      :state="!errors[0]"
                    />

                    <div v-if="field.type === 'select'" :style="errors[0] ? errorStyle : ''">
                      <multiselect
                        v-model="mutableVehicle[field.key]"
                        :options="field.values"
                        :allow-empty="false"
                        :multiple="false"
                      />
                    </div>

                    <div v-if="field.type === 'length'" :style="errors[0] ? errorStyle : ''">
                      <b-form-input
                        v-model="feetLength"
                        :min="field.min"
                        :max="field.max"
                        type="number"
                        :state="!errors[0]"
                        @input="handleLengthChange"
                      />
                    </div>

                    <div v-if="field.type === 'number'" :style="errors[0] ? errorStyle : ''">
                      <b-form-input
                        v-model="mutableVehicle[field.key]"
                        :min="field.min"
                        :max="field.max"
                        type="number"
                        :state="!errors[0]"
                      />
                    </div>

                    <div v-if="field.type === 'select-luxury_tax'">
                      <multiselect
                        v-model="mutableVehicle[field.key]"
                        :options="field.values"
                        label="text"
                        track-by="value"
                        :allow-empty="false"
                        :multiple="false"
                      />
                    </div>

                    <div v-if="field.type === 'select-company'" :style="errors[0] ? errorStyle : '' ">
                      <multiselect
                        v-if="field.type === 'select-company'"
                        v-model="mutableVehicle['company_id']"
                        track-by="company_id"
                        label="name"
                        return="company_id"
                        placeholder="Select A Company"
                        :options="allCompanies"
                        :allow-empty="false"
                        :multiple="false"
                        :state="!errors[0]"
                      />
                    </div>

                    <div v-if="field.type === 'select-make'" :style="errors[0] ? errorStyle : ''">
                      <multiselect
                        v-model="mutableVehicle[field.key]"
                        placeholder-text="Choose A Vehicle Make"
                        :options="allMakes"
                        :allow-empty="false"
                        :multiple="false"
                        @changed="retrieveModels"
                        @input="retrieveModels"
                      />
                    </div>

                    <div v-if="field.type === 'select-model'" :style="errors[0] ? errorStyle : '' ">
                      <multiselect
                        v-model="mutableVehicle[field.key]"
                        placeholder-text="Choose A Vehicle Model"
                        :options="allModels"
                        :multiple="false"
                      />
                    </div>
                    <div v-if="field.type === 'date' && !mutableVehicle.active_renewal" :style="errors[0] ? errorStyle : ''">
                      <v-date-picker
                        id="datepicker-trigger"
                        v-model="mutableVehicle['registration_date']"
                        :value="`${mutableVehicle['registration_date']}`"
                        color="blue"
                        :popover="{ visibility: 'click' }"
                      />
                    </div>
                    <span>{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="justify-content-sm-center">
              <b-button-group>
                <b-button type="submit" variant="primary" :disabled="invalid">
                  Update Vehicle
                </b-button>
              </b-button-group>
            </b-row>
          </validation-observer>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'EditVehicleRegistrationModal',
  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    vehicle: Object,
  },
  data() {
    return {
      date: null,
      active: false,
      loading: false,
      errors: [],
      mutableVehicle: this.vehicle,
      feetLength: null,
      excludedFields: ['registration_date'],
      errorStyle: {
        border: '1px solid #dc3545',
        'border-radius': '0.25rem',
      },
      vehicleTypes: [
        {
          value: 'light_vehicle',
          text: 'Car/Truck',
        },
        {
          value: 'recreational_vehicle',
          text: 'RV',
        },
        {
          value: 'boat',
          text: 'Boat',
        },
        {
          value: 'motorcycle',
          text: 'Motorcycle',
        },
        {
          value: 'heavy_truck',
          text: 'Heavy Truck',
        },
        {
          value: 'travel_trailer',
          text: 'Travel Trailer',
        },
        {
          value: 'cargo_trailer',
          text: 'Cargo Trailer',
        },
      ],
      defaultFields: [
        {
          key: 'company_name',
          label: 'Company',
          type: 'select-company',
        },
        {
          key: 'vehicle_identification_number',
          label: 'VIN',
          type: 'text',
          required: true,
        },
        {
          key: 'model_year',
          label: 'Model Year',
          type: 'number',
          min: 1900,
          max: 3000,
          required: true,
        },
        {
          key: 'make',
          label: 'Make',
          type: 'select-make',
          required: true,
        },
        {
          key: 'model',
          label: 'Model',
          type: 'select-model',
          required: true,
        },
        {
          key: 'color',
          label: 'Color',
          type: 'text',
        },
        {
          key: 'plate_number',
          label: 'Plate Number',
          type: 'text',
        },
        {
          key: 'registration_date',
          label: 'Registration Date',
          type: 'date',
          required: true,
        },
        {
          key: 'msrp',
          label: 'MSRP',
          type: 'number',
          min: 0,
          max: 100000000,
          required: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('companies', [
      'companies',
    ]),
    ...mapGetters('vehicleRegistration', [
      'makes',
      'models',
    ]),
    company() {
      return { value: this.mutableVehicle.company_id, name: this.mutableVehicle.company_name }
    },
    allCompanies() {
     return this.companies.map(company => {return { company_id: company.id, name: company.name }})
    },
    allMakes() {
      return this.makes
    },
    allModels() {
      return this.models
    },
  },
  watch: {
    vehicle(vehicle, _) {
      this.mutableVehicle = vehicle
    },
    mutableVehicle(vehicle, _){
      this.open(vehicle)
    },
  },
  methods: {
    ...mapActions('vehicleRegistration', [
      'create',
      'getMakes',
      'getModels',
      'update',
      'findMake',
    ]),
    handleLengthChange(length) {
      this.mutableVehicle['length'] = length * 12
    },
    selectedCompany() {
      return this.mutableVehicle.company_id
    },
    getMake() {
      this.getMakes({ vehicle_type: this.selectedVehicleType })
    },
    retrieveModels() {
      this.getModels({ make: this.mutableVehicle.make })
    },
    close() {
      this.active = false
    },
    open(vehicle) {
      this.mutableVehicle = vehicle
      this.selectedVehicleType = vehicle.vehicle_type
      this.getMake()
      this.mutableVehicle.company_id = this.company
      this.mutableVehicle.registration_date = new Date(vehicle.registration_date)
      if (vehicle.luxury_tax !== null) {
        this.mutableVehicle.luxury_tax = vehicle.luxury_tax ? { text: 'Yes', value: true } : { text: 'No', value: false }
      }
      if (vehicle.length !== null) {
        this.feetLength = (vehicle.length / 12).toFixed(0)
      }
      this.active = true
    },
    assignObjectKeysUsingCopy() {
      let vehicleCopy = Object.assign({}, this.mutableVehicle)
      Object.keys(this.mutableVehicle).forEach((key) => {
        if (typeof(vehicleCopy[key]) === 'object' && !this.excludedFields.includes(key) && vehicleCopy[key] !== null) {
          vehicleCopy[key] = vehicleCopy[key].value
        }
      })
      this.mutableVehicle = vehicleCopy
    },
    async updateVehicle() {
      this.loading = true

      this.assignObjectKeysUsingCopy()
      try {
        await this.update( { vehicle: this.mutableVehicle } )
        this.$emit('updateComplete')
        this.close()
      } catch(error) {
        this.$emit('updateVehicleFailed')
      }
      this.loading = false
    },
    selectedFields() {
      if (this.selectedVehicleType) {
        switch (this.selectedVehicleType) {
          case "light_vehicle":
            return this.lightVehicleFields()
          case "recreational_vehicle":
            return this.rvFields()
          case "boat":
            return this.boatFields()
          case "trailer":
            if (this.mutableVehicle.vehicle_subtype === 'cargo') {
              return this.cargoTrailerFields()
            } else {
              return this.travelTrailerFields()
            }
          case "heavy_truck":
            return this.heavyTruckFields()
          case "motorcycle":
            return this.motorcycleFields()
          default:
            return "light_vehicle"
        }
      }
      return []
    },
    lightVehicleFields() {
      let fields = [
        {
          key: 'luxury_tax',
          label: 'Is this vehicle above the luxury tax threshold?',
          type: 'select-luxury_tax',
          values: [ { text: 'Yes', value: true }, { text: 'No', value: false } ],
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['gasoline', 'diesel', 'electric', 'plug-in hybrid'],
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['permanent', 'annual'],
          required: true,
        },
      ]
      return this.concatFields(fields)
    },
    rvFields() {
      let fields = [
        {
          key: 'rv_class',
          label: 'RV Class',
          type: 'select',
          values: ['Class A', 'Class B','Class C'],
          required: true,
        },
        {
          key: 'luxury_tax',
          label: 'Is this vehicle above the luxury tax threshold?',
          type: 'select-luxury_tax',
          values: [ { text: 'Yes', value: true }, { text: 'No', value: false } ],
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['gasoline', 'diesel', 'electric', 'plug-in hybrid'],
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['permanent'],
          required: true,
        },
      ]
      return this.concatFields(fields)
    },
    boatFields() {
      let fields = [
        {
          key: 'length',
          label: 'Length (ft)',
          type: 'length',
          required: true,
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['gasoline', 'diesel', 'electric', 'plug-in hybrid'],
          required: true,
        },
        {
          key: 'boat_propulsion',
          label: 'Boat Propulsion',
          type: 'select',
          values: ['Aux/Sail', 'Hand Prop','Inboard', 'Jet', 'Inb-Outb','Sail only', 'Other'],
          required: true,
        },
        {
          key: 'vessel_material',
          label: 'Vessel Material',
          type: 'select',
          values: ['Aluminum', 'Cement', 'Plastic/Fiberglass','Steel', 'Wood', 'Other'],
          required: true,
        },
        {
          key: 'vessel_type',
          label: 'Vessel Type',
          type: 'select',
          values: [
            'Amphibious', 'Cruiser', 'House Boat','Inflatable', 'Kayak/Canoe',
            'Pontoon', 'Row Boat', 'Runabout','Sailboat', 'Other',
          ],
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['permanent'],
          required: true,
        },
      ]
      return this.concatFields(fields)
    },
    travelTrailerFields() {
      let fields = [
        {
          key: 'length',
          label: 'Length (ft)',
          type: 'length',
          min: 0,
          max: 100000,
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['permanent'],
          required: true,
        },
        {
          key: 'vehicle_subtype',
          value: 'travel_trailer',
          required: true,
        },
      ]
      return this.concatFields(fields)
    },
    cargoTrailerFields() {
      let fields = [
        {
          key: 'length',
          label: 'Length (ft)',
          type: 'length',
          min: 0,
          max: 100000,
          required: true,
        },
        {
          key: 'weight',
          label: 'Weight (Lbs)',
          type: 'number',
          min: 0,
          max: 100000,
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['permanent'],
          required: true,
        },
        {
          key: 'vehicle_subtype',
          value: 'cargo_trailer',
          required: true,
        },
      ]
      return this.concatFields(fields)
    },
    heavyTruckFields() {
      let fields = [
        {
          key: 'rv_class',
          label: 'RV Class',
          required: true,
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['gasoline', 'diesel', 'electric', 'plug-in hybrid'],
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['annual'],
          required: true,
        },
      ]
      return this.concatFields(fields)
    },
    motorcycleFields() {
      let fields = [
        {
          key: 'engine_displacement',
          label: 'Displacement (CCs)',
          type: 'number',
          required: true,
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['gasoline', 'diesel', 'electric', 'plug-in hybrid'],
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['permanent'],
          required: true,
        },
      ]
      return this.concatFields(fields)
    },
    concatFields(fields) {
      return [...this.defaultFields, ...fields]
    },
  },
}
</script>

<style scoped>
  .centered {
    text-align: center;
  }
</style>
